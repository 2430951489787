.container{
    width: 70%;
    margin: 0 auto;
}
/* fv_area */
.fv_area{
    height: 60vh;
    display: flex;
    align-items: center;
}
.fv_area h1{
    font-size: 110px;
    letter-spacing: 5px;
    margin-top: 75px;
}
/* about_area */
.about_area{
    padding: 100px 0;
}
.about_area > .flex{
    display: flex;
    align-items: flex-start;
    justify-content: center;
}
.about_area > .flex > div:first-child{
    flex: 4;
}
.about_area > .flex > div:nth-child(2){
    flex: 6;
}
.about_area > .flex > div:first-child h2{
    font-size: 36px;
    line-height: 1;
}
.about_area > .flex > div:first-child h2 > span{
    font-size: 16px;
}
.about_area > .flex > div:nth-child(2) > div{
    margin-bottom: 35px;
    font-size: 22px;
}
.about_area > .flex > div:nth-child(2) > p{
    line-height: 2;
    font-size: 16px;
}
/* concept_area */
.concept_area{
    padding: 100px 0;
}
.concept_area > h2{
    font-size: 36px;
    line-height: 1;
}
.concept_area > h2 > span{
    font-size: 16px;
}
/* feature_area */
.feature_area{
    padding: 100px 0;
}
.feature_area > h2{
    font-size: 36px;
    line-height: 1;
}
.feature_area > h2 > span{
    font-size: 16px;
}
/* cta_area */
.cta_area{
    padding: 100px 0;
}
.cta_area > p{
    text-align: center;
}
.cta_area > a {
    display: block;
    margin-top: 50px;
    text-decoration: none;
}
.cta_area > a > .flex {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #28282886;
    height: 200px;
    padding: 0 150px;
    border-radius: 25px;
    position: relative;
    overflow: hidden;
    border: 1px solid #fff;
}
.cta_area > a > .flex::before {
    content: "";
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: #ffffff31;
    position: absolute;
    top: 15px;
    left: 97%;
    transition: all .8s;
    z-index: 0;
}
.cta_area > a:hover > .flex::before {
    width: 300%;
    height: 300%;
    transform: translate(-80%, -50%);
}

.cta_area > a > .flex > div:first-child {
    flex: 6.5;
    font-size: 48px;
    font-weight: bold;
}
.cta_area > a > .flex > div:nth-child(2) {
    flex: 3.5;
    font-size: 20px;
    text-align: right;
    font-weight: bold;
}
/* works_area */
.works_area{
    padding: 100px 0;
    overflow-x: auto;
    overflow-y: hidden;
    z-index: 1000;
}
.works_area > h2{
    font-size: 36px;
    line-height: 1;
}
.works_area > h2 > span{
    font-size: 16px;
}

/* process_area */
.process_area{
    padding: 100px 0;
}
.process_area > h2{
    font-size: 36px;
    line-height: 1;
}
.process_area > h2 > span{
    font-size: 16px;
}
.process_area > .cards{
    margin-top: 100px;
}
.process_area > .cards .flex{
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #fff;
    padding: 50px 0;
}
.process_area > .cards .flex > div:first-child{
    flex: 3;
}
.process_area > .cards .flex > div:nth-child(2){
    flex: 7;
    padding-left: 20px;
}
.process_area > .cards .flex > div:first-child > div {
    position: relative;
    border: 1px solid #ffffff7e;
    width: 250px;
    height: 250px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 0 auto;
    font-size: 20px;
}
.process_area > .cards .flex > div:first-child > div::before {
    content: '';
    position: absolute;
    top: 0%;
    left: 50%;
    transform: translateX(-50%);
    width: 10px;
    height: 10px;
    margin-top: -5px; /* Half the height */
    margin-left: -5px; /* Half the width */
    background-color: rgb(255, 255, 255); /* Red color for visibility */
    border-radius: 50%;
    animation: rotateAnimation 5s ease infinite;
    transform-origin: 50% calc(50% + 125px); /* Adjusted to align with the big circle border */
}
@keyframes rotateAnimation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
}
.process_area > .cards .flex > div:nth-child(2) > div{
    font-size: 20px;
    margin-bottom: 35px;
}
.process_area > .cards .flex > div:nth-child(2) > p{
    line-height: 2;
    font-size: 18px;
}
/* client_area */
.client_area {
    padding: 100px 0;
}
.client_area > h2{
    font-size: 36px;
    line-height: 1;
}
.client_area > h2 > span{
    font-size: 16px;
}
.client_area > ul{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 100px;
}
.client_area > ul li{
    list-style: none;
    flex: calc(100%/5);
    margin-bottom: 100px;
}
.client_area > ul li img{
    display: block;
    width: 80%;
    margin: 0 auto;
}
@media screen and (max-width:768px){
    .container{
        width: 85%;
        margin: 0 auto;
    }
    .fv_area h1{
        font-size: 72px;
    }
    
    .about_area > .flex{
        display: block;
    }
    .about_area > .flex > div:first-child h2{
        font-size: 32px;
    }
    .about_area > .flex > div:first-child h2 > span{
        font-size: 12px;
    }
    .about_area > .flex > div:nth-child(2){
        margin-top: 50px;
    }
    .feature_area > h2{
        font-size: 32px;
    }
    .feature_area > h2 > span{
        font-size: 12px;
    }
    .cta_area{
        padding: 0px 0 100px;
    }
    .cta_area > p{
        font-size: 14px;
    }
    .cta_area > a > .flex {
        height: 150px;
        padding: 0 25px;
    }
    .cta_area > a > .flex::before {
        left: 95%;
    }
    .cta_area > a > .flex > div:first-child {
        flex: 6;
        font-size: 42px;
    }
    .cta_area > a > .flex > div:nth-child(2) {
        flex: 4;
        font-size: 18px;
        text-align: center;
    }

    
    .works_area > h2{
        font-size: 32px;
    }
    .works_area > h2 > span{
        font-size: 12px;
    }
    .process_area > h2{
        font-size: 32px;
    }
    .process_area > h2 > span{
        font-size: 12px;
    }
    
    .process_area > .cards .flex > div:first-child > div {
        width: 150px;
        height: 150px;
    }
    .process_area > .cards .flex > div:first-child > div::before {
        transform-origin: 50% calc(50% + 75px); /* Adjusted to align with the big circle border */
    }
    .process_area > .cards .flex > div:nth-child(2) > div{
        margin-bottom: 25px;
    }
    .process_area > .cards .flex > div:nth-child(2) > p{
        font-size: 13px;
    }
    .client_area > h2{
        font-size: 32px;
    }
    .client_area > h2 > span{
        font-size: 12px;
    }
}
@media screen and (max-width:430px){
    .fv_area h1{
        font-size: 48px;
    }
    .feature_area{
        padding: 100px 0 0;
    }
    .cta_area > a > .flex {
        height: 90px;
        padding: 0 15px;
    }
    .cta_area > a > .flex > div:first-child {
        flex: 6;
        font-size: 28px;
    }
    .cta_area > a > .flex > div:nth-child(2) {
        font-size: 12px;
    }
    .cta_area > a > .flex::before {
        left: 94%;
    }
    .process_area > .cards .flex{
        display: block;
    }
    .process_area > .cards .flex > div:nth-child(2){
        margin-top: 35px;
        text-align: center;
        padding-left: 0;
    }
    .client_area > ul li{
        list-style: none;
        flex-basis: 1;
        margin-bottom: 50px;
    }

}