.threescroll_wrapper canvas{
    z-index: 10;
    width: 100% !important;
    height: 100vh !important;
    background-color: transparent;
    
}
.threescroll_wrapper  h1{
    font-size: 100px;
    font-weight: 400;
    letter-spacing: -0.05em;
    line-height: 0.7em;
    margin: 0;
    padding: 0;
}
.threescroll_wrapper a{
    pointer-events: all;
    color: black;
    text-decoration: none;

}

.threescroll_wrapper ::-webkit-scrollbar {
    width: 12px;
    display: block;
}
.threescroll_wrapper ::-webkit-scrollbar-track {
    background-color: #34235f77;
    border-radius: 10px;
}
.threescroll_wrapper ::-webkit-scrollbar-thumb {
    background-color: #ffffff1b;
    border-radius: 10px;
}
  
.threescroll_wrapper {
    scrollbar-width: thin;
    scrollbar-color: #888 #f1f1f1;
}
.head{
    text-align: center;
}
.head .title{
    font-size: 56px;
}
.head .title span{
    display: block;
    font-size: 18px;
}
.head p{
    width: 70%;
    margin: 50px auto 0;
    line-height: 2;
}
.section_1{
    position: absolute;
    top: 50vh;
}
.section_wrapper{
    width: 50%;
}
.section_wrapper .title{
    font-size: 56px;
    font-weight: bold;
}
.section_wrapper .title span{
    display: block;
    margin-bottom: 25px;
    font-size: 18px;
}
.section_wrapper p{
    margin: 50px 0;
    line-height: 2;    
}
.section_wrapper button{
    color: #fff !important;
    width: 100% !important;
    max-width: 150px !important;
    padding: 12px !important;
    border-radius: 35px !important;
    text-transform: none !important;
}
.section_wrapper img{
    border-radius: 35px !important;
}
.section_2{
    position: absolute;
    top: 180vh;
    right: 0;
}
.section_3{
    position: absolute;
    top: 310vh;
}
@media screen and (max-width:768px) {
    .head .title{
        font-size: 32px;
    }
    .head .title span{
        display: block;
        font-size: 12px;
    }
    .head p{
        width: 100%;
        margin: 50px auto 0;
        line-height: 2;
        font-size: 13px;
    }
    .section_wrapper{
        width: 100%;
    }
    .section_1{
        position: absolute;
        top: 70vh;
    }
    .section_2{
        position: absolute;
        top: 210vh;
        right: 0;
    }
    .section_3{
        position: absolute;
        top: 350vh;
    }

    .section_wrapper p {
        font-size: 13px;
    }
    
}