/* body::before {
    content: "";
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: 
        linear-gradient(rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.7)), 
        url(/public/images/bg-img1.jpg);
    background-size: cover;
    background-position: center;
    z-index: -100;
  } */

/* ContactForm */
.css-v4u5dn-MuiInputBase-root-MuiInput-root:before,
.css-1rb63tl-MuiInputBase-root-MuiInput-root:before{
    border-bottom: 1px solid #ffffff !important;
}
.css-zrqpq1::before,
.css-repss6::before{
    border-bottom: 1px solid #ffffff !important;
}
.css-v4u5dn-MuiInputBase-root-MuiInput-root::after,
.css-1rb63tl-MuiInputBase-root-MuiInput-root::after{
    border-bottom: 1px solid #404697 !important;
}
.css-xn6ruc>:not(style){
    margin: 10px 0 !important;
}
/* carousel */
.css-1qzevvg {
    position: relative;
    display: flex;
    height: 40px;
    margin: 35px auto 0px !important;
    width: 100% !important;
    -webkit-box-pack: justify;
    justify-content: space-between;
}



/* scrollcontents */
#root > main > div > section.About_works_area__4PBzw > div > div > div > div{
    opacity: 0;
}

@media screen and (max-width:430px) {
    .css-doq0dk {
        height: 50% !important;
    }
    .css-1fzpoyk img {
        max-height: 45% !important;
    }
    .css-1qzevvg{
        /* display: none !important; */
    }
    
}