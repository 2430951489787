*{
  margin: 0;
  padding: 0;
  font-family: serif;
  box-sizing: border-box;
  color: #fff;
}
body{
    /* background-image: url('/public/images/bg-img.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed; */
    background-color: rgba(0, 0, 0, 0.945);
}