.container{
    width: 70%;
    margin: 0 auto;
}
/* contents_area */
.contents_area{
    padding: 100px 0;
    margin: 150px 0;
    background-color: #cecece84;
    border-radius: 20px;
}
.title{
    color: #fff;
    width: 80%;
    margin: 0 auto;
    font-weight: bold;
    font-size: 36px;
    padding-bottom: 35px;
    border-bottom: 1px solid #fff;
}
.title > div{
    margin-bottom: 35px;
    font-size: 18px;
}
.title > div span{
    margin-left: 15px;
    color: #b6b6b6;
    font-size: 15px;
}
.contents_area img{
    display: block;
    width: 80%;
    margin: 50px auto;
    border-radius: 15px;
}
.text{
    width: 80%;
    margin: 0 auto;
}
.text > div{
    margin-top: 35px;
}
.text > div > div{
    margin-bottom: 15px;
}
.text > div > ul{
    list-style: none;
}
.text > div > ul:not(:last-child){
    margin-bottom: 25px;
}
@media screen and (max-width:768px){
    .container{
        width: 85%;
        margin: 0 auto;
    }
    .contents_area{
        padding: 50px 0;
        margin: 150px 0;
    }
    .title{
        width: 90%;
        font-size: 22px;
        padding-bottom: 30px;
        border-bottom: 1px solid #fff;
    }
    .title > div{
        margin-bottom: 30px;
        font-size: 16px;
    }
    .title > div span{
        margin-left: 15px;
        color: #2667B2;
        font-size: 14px;
    }
    .contents_area img{
        width: 90%;
        margin: 35px auto;
    }
    .text{
        width: 90%;
        margin: 0 auto;
        font-size: 13px;
    }
}