.container{
    width: 70%;
    margin: 0 auto;
}
/* fv_area */
.fv_area{
    height: 60vh;
    display: flex;
    align-items: center;
}
.fv_area h1{
    font-size: 110px;
    letter-spacing: 5px;
    margin-top: 75px;
}
/* form_area */
.form_area {
    padding: 100px 0;
}
.form_area .flex{
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(92, 92, 92, 0.3);
    padding: 50px 100px;
    width: 100%;
    margin: 0 auto;
    position: relative;
    border-radius: 15px;
}
.form_area .flex > div > h3{
    text-align: center;
    margin-bottom: 25px;
    font-size: 22px;
}
.form_area .flex > div > p{
    text-align: center;
}
.button_wrapper{
    text-align: center;
    margin-top: 35px;
}
.form_area .flex > div button{
    color: #fff !important;
    border: 1px solid #fff !important;
    width: 100% !important;
    max-width: 180px !important;
    padding: 10px !important;
    border-radius: 25px !important;
    text-transform: none !important;
}
.form_area .flex > div button a{
    text-decoration: none;
    display: block;
    width: 100%;
    height: 100%;
}
@media screen and (max-width:968px){
    .form_area .flex{
        padding: 50px 50px;
    }
    .form_area .flex > div:first-child{
        padding-right: 25px;
    }

}
@media screen and (max-width:768px){
    .container{
        width: 85%;
        margin: 0 auto;
    }
    .fv_area h1{
        font-size: 72px;
    }
    .form_area .flex{
        flex-direction: column-reverse;
        align-items: flex-start;
        padding: 50px;
    }
    .form_area .flex > div{
        flex: 3;
        width: 100%;
    }
    .button_wrapper{
        margin-top: 30px;
        text-align: center;
    }
    .form_area .flex > div button{
        max-width: 150px !important;
        padding: 8px !important;
    }
    
}
@media screen and (max-width:430px){
    .fv_area h1{
        font-size: 48px;
    }
    .form_area .flex{
        padding: 50px 25px;
    }

}