.container{
    width: 70%;
    margin: 0 auto;
}
/* fv_area */
.fv_area{
    height: 60vh;
    display: flex;
    align-items: center;
}
.fv_area h1{
    font-size: 110px;
    letter-spacing: 5px;
    margin-top: 75px;
}
/* prof_area */
.prof_area{
    padding: 100px 0;
    position: relative;
}
.prof_area > h2{
    font-size: 36px;
    line-height: 1;
}
.prof_area > h2 > span{
    font-size: 16px;
}
.prof_area > .info{
    margin: 100px 0;
}
.prof_area > .info > .flex{
    display: flex;
    border-top: 1px solid #fff;
    padding: 35px 0;
}
.prof_area > .info > .flex:last-child{
    border-bottom: 1px solid #fff;
}
.prof_area > .info > .flex > div:first-child{
    flex: 2;
}
.prof_area > .info > .flex > div:nth-child(2){
    flex: 8;
}
@media screen and (max-width:768px){
    .container{
        width: 85%;
        margin: 0 auto;
    }
    .fv_area h1{
        font-size: 72px;
    }
    .prof_area > h2{
        font-size: 32px;
        line-height: 1;
    }
    .prof_area > h2 > span{
        font-size: 12px;
    }
}
@media screen and (max-width:430px){
    .fv_area h1{
        font-size: 48px;
    }
    .prof_area > .info > .flex > div:first-child{
        flex: 3;
    }
    .prof_area > .info > .flex > div:nth-child(2){
        flex: 7;
    }

}