footer{
    background: rgba(0, 0, 0, 0.7);
    padding: 25px 0;
}
footer .flex{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80%;
    margin: 0 auto;
    padding: 150px 0;
}
footer .flex > div:first-child{
    flex: 1;
}
footer .flex > div:nth-child(2){
    flex: 1;
    text-align: right;
}
footer .flex > div:first-child img{
    display: block;
    width: 30%;
}
footer .flex > div:nth-child(2) ul li{
    list-style: none;
    display: inline-block;
    margin: 0 35px;
}
footer .flex > div:nth-child(2) ul li a{
    text-decoration: none;
}
footer > p{
    text-align: center;
    opacity: .5;
    font-size: 13px;
}
@media screen and (max-width:768px) {
    footer .flex{
        display: block;
        width: 80%;
        padding: 100px 0;
    }
    footer .flex > div:first-child{
        margin-bottom: 35px;
    }
    footer .flex > div:nth-child(2) ul{
        display: flex;
        justify-content: center;
    }
    footer .flex > div:nth-child(2) ul li{
        margin: 0 15px;
        font-size: 12px;
    }
    footer .flex > div:first-child img{
        width: 35%;
        margin: 0 auto;
        
    }
    
}
@media screen and (max-width:430px){
    footer .flex > div:first-child img{
        width: 50%;
        margin: 0 auto;
    }
} 