header{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 80px;
    position: fixed;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 95%;
    z-index: 1000;
}
header h1 {
    flex: 3;
    
}
header h1 img{
    display: block;
    width: 40%;
}
header nav {
    flex: 7;
}
header nav ul {
    text-align: right;
}
header nav ul li {
    display: inline-block;
    margin-left: 3%;
    position: relative;
    transition: all .3s;
    overflow: hidden;
}

header nav ul li::before {
    content: '';
    position: absolute;
    bottom: 3px;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 2px;
    background-color: #ffffff;
    transition: width .3s; 
}

header nav ul li:hover:before {
    width: 70%;
}

header nav ul li a{
    text-decoration: none;
    color: #fff;
    display: inline-block;
    width: 100%;
    padding: 3px 20px;
}


/* ハンバーガーメニューのスタイル */
.menuIcon {
    display: none;
    flex-direction: column;
    cursor: pointer;
    gap: 5px;
}
  
.menuIcon > div {
    width: 30px;
    height: 4px;
    background-color: #fff;
}
.closeButton {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 24px;
    cursor: pointer;
    color: #fff;
    background: transparent;
    border: none;
    display: none;
}
  
@media (max-width: 768px) {
    header h1 img{
        width: 30%;
    }
    .menuIcon {
        display: flex;
    }
    nav {
        position: fixed;
        top: 0;
        right: -100%;
        width: 250px;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.9);
        transition: right 0.3s;
        z-index: 1010;
        padding-top: 60px;
    }
    nav.open {
        right: 0;
    }
    nav ul {
        padding: 100px 20px 20px;
        display: flex;
        flex-direction: column;
        gap: 20px;
        align-items: flex-start;
    }
    
    header nav ul li:last-child{
        border: none;
    }
    .closeButton {
        display: block;
    }
}
@media screen and (max-width:430px){
    header h1 img{
        width: 50%;
    }

}