.container{
    width: 70%;
    margin: 0 auto;
}
/* fv_area */
.fv_area{
    height: 60vh;
    display: flex;
    align-items: center;
}
.fv_area h1{
    font-size: 110px;
    letter-spacing: 5px;
    margin-top: 75px;
}
/* contents_area */
.contents_area{
    padding: 100px 0;
}
.tab button{
    color: #fff !important;
    text-transform: none !important;
}
.tab_button.category{
    opacity: 0.5;
}
.tab_button span{
    border-radius: 25px;
}
.contents_area > div:first-child{
    background: rgba(0, 0, 0, 0.5);
    padding: 35px 15px;
}
.contents_area .content{
    margin-top: 100px;
}
.contents_area .content .card {
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: center center !important;
    border-radius: 15px;
    padding: 35px;
}
.contents_area .content .card:not(:last-child){
    margin-bottom: 50px;
}
.contents_area .content .card .date,
.contents_area .content .card .title{
    margin-bottom: 25px;
}
.contents_area .content .card .date{
    color: #d6d6d6;
    font-size: 14px;
}
.contents_area .content .card .date span{
    margin-right: 10px;
    font-size: 16px;
    vertical-align: middle;
}
.contents_area .content .card .title{
    width: 70%;
    line-height: 2;
    font-size: 18px;
}
.button_wrapper{
    text-align: right;
}
.button_wrapper a{
    color: #fff !important;
    border: 1px solid #fff !important;
    width: 100% !important;
    max-width: 180px !important;
    padding: 10px !important;
    border-radius: 25px !important;
    text-transform: none !important;
}
.button_wrapper button a{
    text-decoration: none;
    display: block;
    width: 100%;
    height: 100%;
}
@media screen and (max-width:768px){
    .container{
        width: 85%;
        margin: 0 auto;
    }
    .fv_area h1{
        font-size: 72px;
    }
    .contents_area .content .card .title{
        margin-bottom: 0;
        font-size: 16px;
        width: 100%;
    }
    .button_wrapper a{
        max-width: 150px !important;
        padding: 8px !important;
    }
}
@media screen and (max-width:430px){
    .fv_area h1{
        font-size: 48px;
    }
    .contents_area .content .card {
        padding: 25px;
    }
    .contents_area .content .card .date{
        font-size: 12px;
    }
    .contents_area .content .card .date span{
        font-size: 14px;
    }
    .button_wrapper{
        margin-top: 20px;
    }
    
    

}