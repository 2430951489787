.container{
    width: 70%;
    margin: 0 auto;
}
/* fv_area */
.fv_area{
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
/* about_area */
.about_area{
    padding: 100px 0;
}
.about_area > h2{
    font-size: 36px;
    line-height: 1;
}
.about_area > h2 > span{
    font-size: 16px;
}
.about_area > p{
    margin: 150px 0;
    font-size: 20px;
    text-align: center;
    line-height: 1.7;
}
.three_scroll{
    background-color: transparent;
}
.about_area > .flex{
    display: flex;
    justify-content: center;
    align-items: flex-end;
    margin-top: 100px;
}
.about_area > .flex > div:first-child{
    flex: 6;
    line-height: 2;
}
.about_area > .flex > div:nth-child(2){
    flex: 4;
    text-align: center;
}
.about_area > .flex > div:nth-child(2) a{
    color: #fff !important;
    border: 1px solid #fff !important;
    width: 100% !important;
    max-width: 180px !important;
    padding: 10px !important;
    border-radius: 25px !important;
    text-transform: none !important;
}
/* company_area */
.company_area{
    padding: 100px 0;
}
.company_area > h2{
    font-size: 36px;
    line-height: 1;
}
.company_area > h2 > span{
    font-size: 16px;
}
.company_area > p{
    width: 60%;
    margin: 100px auto;
    font-size: 24px;
    line-height: 2;
}
.company_area > .flex{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 100px;
}
.company_area > .flex > div:first-child{
    flex: 1;
}
.company_area > .flex > div:nth-child(2){
    flex: 1;
    text-align: right;
}
.company_area > .flex > div:first-child > div{
    margin-bottom: 35px;
    font-size: 36px;
    line-height: 1.7;
}
.company_area > .flex > div:first-child > p{
    font-size: 20px;
    margin-bottom: 35px;
    line-height: 2;
}
.company_area > .flex > div:first-child > a{
    color: #fff !important;
    border: 1px solid #fff !important;
    width: 100% !important;
    max-width: 180px !important;
    padding: 10px !important;
    border-radius: 25px !important;
    text-transform: none !important;
}
.company_area > .flex > div:nth-child(2) img{
    display: block;
    width: 60%;
    margin-left: auto;
}
.google_map{
    width: 90%;
    margin-left: auto;
    height: 700px;
    border-radius: 15px;
}
/* news_area */
.news_area{
    padding: 100px 0;
}
.news_area > h2{
    font-size: 36px;
    line-height: 1;
}
.news_area > h2 > span{
    font-size: 16px;
}
.news_area .cards{
    width: 85%;
    margin: 100px auto 0;
}
.news_area .cards .card{
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 1px solid #fff;
    padding: 50px 0;
}
.news_area .cards .card:last-child{
    border-bottom: 1px solid #fff;
}
.news_area .cards .card > div{
    flex: 1;
}
.news_area .cards .card > div:first-child img{
    display: block;
    width: 80%;
    border-radius: 15px;
}
.news_area .cards .card > div:nth-child(2) > div:first-child{
    margin-bottom: 35px;
    font-size: 15px;
}
.news_area .cards .card > div:nth-child(2) > p{
    margin-bottom: 35px;
    line-height: 2;
}
.news_area .cards .card > div:nth-child(2) > div:nth-child(3) a{
    color: #fff !important;
    border: 1px solid #fff !important;
    width: 100% !important;
    max-width: 180px !important;
    padding: 10px !important;
    border-radius: 25px !important;
    text-transform: none !important;
}
.news_area .button_wrapper{
    width: 85%;
    margin: 50px auto 0;
    text-align: right;
}
.news_area .button_wrapper a{
    color: #fff !important;
    border: 1px solid #fff !important;
    width: 100% !important;
    max-width: 180px !important;
    padding: 10px !important;
    border-radius: 25px !important;
    text-transform: none !important;
}
@media screen and (max-width:768px){
    .container{
        width: 85%;
        margin: 0 auto;
    }
    .about_area > h2{
        font-size: 32px;
    }
    .about_area > .flex > div:first-child {
        font-size: 13px;
    }
    .about_area > h2 > span{
        font-size: 12px;
    }
    .about_area > p{
        margin: 100px 0;
        font-size: 16px;
    }
    .about_area > h3{
        width: 65%;
        font-size: 24px;
    }
    .about_area > .flex{
        display: block;
    }
    .about_area > .flex > div:nth-child(2){
        margin-top: 35px;
        text-align: right;
    }
    .about_area > .flex > div:nth-child(2) a{
        max-width: 150px !important;
        padding: 8px !important;
    }
    .company_area > h2{
        font-size: 32px;
    }
    .company_area > h2 > span{
        font-size: 12px;
    }
    .company_area > .flex{
        display: block;
    }
    .company_area > .flex > div:first-child > div{
        font-size: 24px;
    }
    .company_area > .flex > div:first-child > p{
        font-size: 13px;
    }
    .company_area > .flex > div:first-child > a{
        max-width: 150px !important;
        padding: 8px !important;
    }
    .company_area > .flex > div:nth-child(2){
        margin-top: 50px;
    }
    
    .google_map{
        width: 100%;
        height: 300px;
    }
    .news_area > h2{
        font-size: 32px;
    }
    .news_area > h2 > span{
        font-size: 12px;
    }
    .news_area .cards{
        width: 100%;
    }
    .news_area .cards .card{
        display: block;
    }
    .news_area .cards .card > div:first-child img{
        width: 100%;
    }
    .news_area .cards .card > div:nth-child(2) > div:first-child{
        font-size: 13px;
        margin: 15px 0 3px;
    }
    
    .news_area .cards .card > div:nth-child(2) > p{
        font-size: 14px;
        margin: 10px 0;
        text-align: center;
    }
    .news_area .cards .card > div:nth-child(2) > div:nth-child(3) {
        text-align: center;
        margin-top: 15px;
    }
    .news_area .cards .card > div:nth-child(2) > div:nth-child(3) a{
        max-width: 150px !important;
        padding: 8px !important;
    }
    .news_area .cards .card > div:nth-child(2) > div:nth-child(3) button{
        display: block;
        margin-left: auto;
    }
    .news_area .button_wrapper{
        width: 100%;
    }
    .news_area .button_wrapper a{
        max-width: 150px !important;
        padding: 8px !important;
    }
}
@media screen and (max-width:430px){
    .news_area .cards .card{
        padding: 35px 0;
    }
}