.carousel{
    margin: 100px auto 0 !important;
    height: 600px;
    width: 100%;
}
.slide_content{
    text-align: center;
}
.slider_img{
    cursor: pointer;
    margin: 0 auto;
    height: 380px;
}
.slide_text{
    margin-top: 15px;
}
.slide_text .title{
    font-size: 24px;
    line-height: 1.8;
}
.slide_text .title span{
    display: block;
    font-size: 14px;
    opacity: 0.7;
}
.slide_text p{
    line-height: 2;
    margin-top: 25px;
    padding-bottom: 25px;
    font-size: 14px;
    width: 100%;
    margin: 25px auto 0;
    text-align: left;
}
.slide_text .marks{
    border-top: 1px solid #fff;
    padding-top: 25px;
}
.slide_text .marks .mark{
    border: 1px solid #fff;
    display: inline;
    margin: 0 8px;
    padding: 5px 15px;
    font-size: 12px;
    border-radius: 25px;
}
.slide_text .marks .mark:not(:last-child){
    margin-bottom: 8px;
}
@media screen and (max-width:968px) {
    .slide_text{
        display: none;
    }
}